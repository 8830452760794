<template>
    <div class="share-main">
        <div class="title">{{ companyInfo.name }}</div>
        <div class="jiaru">
            <span>受邀加入“优信立信计划”</span>
        </div>
        <div class="content">
            <div>
                <span style="color: #000000; font-weight: 700">{{
                    companyInfo.name
                }}</span>
                于{{ companyInfo.time }}申请加入优信立信计划，
                并签署《守信承诺书》。经立信计划官方审核，
                未发现重大失信行为，符合“优信立信企业”认定条件，
                <span style="color: #bd0406"
                    >获得优信立信企业”称号，以资鼓励。</span
                >
            </div>
            <div>运营单位</div>
            <div>北京优信立信企业信用评估中心</div>
            <div class="qiye">
                <template v-if="companyInfo.img">
                    <div class="name">
                        {{ companyInfo.name }}
                    </div>
                    <img :src="companyInfo.img" alt="" />
                </template>
                <van-loading v-else type="spinner" color="#1989fa" />
            </div>
        </div>
        <div class="beian">京ICP备2023006559号-1</div>
    </div>
</template>

<script>
import { qrCodeInfoApi } from "@/api/index.js";
export default {
    name: "share",
    data() {
        return {
            companyInfo: {
                name: "公司/个体户名称xxxxxx",
                time: "XXXX年XX月XX日",
                img: "",
            },
            cid: "",
        };
    },
    created() {
        this.cid = this.$route.params.cid;
        if (this.cid) {
            this.getDataFun();            
        } else {
            this.$toast("企业ID为空");
        }
    },
    methods: {
        getDataFun() {
            let self = this;
            qrCodeInfoApi(this.cid).then((res) => {
                console.log(res);
                if(res.code == 200){
                    let data = res.data;
                    self.companyInfo.name = data.comp_name;
                    self.companyInfo.time = data.joindate.replace("-", "年").replace("-", "月") + "日";
                    self.companyInfo.img = data.order.product.pre_img;

                    //微信分享触发事件
                    let option = self.util.shareData();
                    option.link = `${location.origin}/share/${self.cid}`;
                    option.title = data.comp_name;
                    // console.log(option)
                    self.util.weixinShareFun(option);
                }else{
                    self.$toast(res.msg || '服务器请求错误')
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.share-main {
    width: 375px;
    height: 100vh;
    background: url("~@/assets/img/share-back.png");
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    box-sizing: border-box;
    .title {
        font-size: 21px;
        color: #ffffff;
        font-weight: 700;
    }
    .jiaru {
        width: 204px;
        height: 39px;
        background: url("~@/assets/img/jiaru-img.png") no-repeat;
        background-size: 100% 100%;
        margin-top: 13px;
        font-size: 11px;
        color: #ffffff;
        text-align: center;
        padding-top: 8px;
        box-sizing: border-box;
    }
    .content {
        width: 350px;
        height: auto;
        background: url("~@/assets/img/share-content.png") no-repeat;
        background-size: 100% 100%;
        margin-top: 12px;
        padding: 31px 24px 35px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .qiye {
            position: relative;
            width: 100%;
            height: 170px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            .name {
                width: 100%;
                position: absolute;
                top: 57px;
                left: 0;
                font-size: 10px;
                text-align: center;
                color: #333333;
            }
            img {
                width: 224px;
                height: 150px;
                margin-top: 20px;
            }
        }
        div {
            &:nth-child(1) {
                font-size: 14px;
                color: #666666;
                line-height: 24px;
            }
            &:nth-child(2) {
                color: #999999;
                font-size: 11px;
                margin-top: 15px;
                margin-left: auto;
            }
            &:nth-child(3) {
                color: #999999;
                font-size: 11px;
                margin-top: 9px;
                margin-left: auto;
            }
        }
    }
    .beian {
        font-size: 11px;
        color: #666666;
        margin-top: 25px;
    }
}
</style>